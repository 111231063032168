

























































































































































































































































































































































import {
	Vue,
	Component,
	PropSync,
	Watch,
	Ref,
	Prop,
} from 'vue-property-decorator'
import { FindVendaUseCase } from '@/usecases'
import { Venda, ItemDaVenda, ResumoDaVenda, Cliente, TipoDeProduto, Produto } from '@/models'
import IniciarUmaDevolucao from '@/usecases/venda/IniciarUmaDevolucao'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { maiorQueZero } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { formatarMoeda } from '@/shareds/formatadores'
import BuscarVendaParaDevolucaoUseCase from '@/usecases/venda/BuscarVendaParaDevolucaoUseCase'
import CampoDeCodigoDaVenda from '@/components/venda/CampoDeCodigoDaVenda.vue'
import { obterDisplayClienteDaVenda, obterTotalDoItem, obterTotalDoItemDevolucao } from '@/shareds/venda-shareds'
import SeletorDeCliente from '@/views/application/clientes/SeletorDeCliente.vue'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import RodapePersonalizado from '../ui/data-tables/RodapePersonalizado.vue'
import Confirmacao from '../ui/Confirmacao.vue'
import DialogoDeDetalhesDaVenda from './DialogoDeDetalhesDaVenda.vue'
import AppLoading from '../ui/AppLoading.vue'
import BuscaDeProdutoDropdown from '../produto/BuscaDeProdutoDropdown.vue'

@Component({
	components: {
		CampoDeCodigoDaVenda,
		SeletorDeCliente,
		RodapePersonalizado,
		Confirmacao,
		DialogoDeDetalhesDaVenda,
		AppLoading,
		BuscaDeProdutoDropdown,
	},
})
export default class DialogoDeDevolucaoDeVendaSemTurno extends Vue {
	@PropSync('mostra', { type: Boolean, default: false }) syncMostra!: boolean
	@Ref() form!: HTMLFormElement
	@Ref() campoDeNumeroDaVenda!: HTMLInputElement
	@Ref() campoDeNumeroDaNota!: HTMLInputElement
	@Ref() checkboxDeSelecionarTodos!: HTMLInputElement
	@Ref() campoDeProduto!: HTMLInputElement
	@Prop() parametroIdentificador!: string | null
	
	headers = [
		{
			text: 'Produto',
			value: 'produto.nome',
			class: 'text-no-wrap',
			width: '100%',
		},
		{
			text: 'Código',
			value: 'displayEans',
			class: 'text-no-wrap',
			align: 'right',
		},
		{ text: 'Quantidade', value: 'quantidade', class: 'text-no-wrap' },
		{
			text: 'Valor (R$)',
			value: 'preco',
			class: 'text-no-wrap',
			align: 'right',
		},
	]

	parametros = criarParametrosDeBusca(VendaModule.lojaDaVenda?.id)

	selecionados: ItemDaVenda[] = []
	etapaDaDevolucao: 'filtros' | 'pesquisas' | 'escolhendo-itens' = 'filtros'
	findVendaUseCase = new FindVendaUseCase()
	iniciarDevolucao = new IniciarUmaDevolucao()
	venda: Venda | null = null
	vendaOriginal: Venda | null = null
	devolucaoParcial = false

	maiorQueZero = maiorQueZero
	formatarMoeda = formatarMoeda
	obterTotalDoItem = obterTotalDoItem
	obterTotalDoItemDevolucao = obterTotalDoItemDevolucao

	pagina = 1
	buscando = false
	iniciandoDevolucao = false
	busca = ''
	
	paginacao = {
		page: 0,
		itemsPerPage: 10,
		itemsLength: 0,
	}
	vendasResumidas: ResumoDaVenda[] = []
	buscandoVendas = false

	headersVendas = [
		{ text: 'Identificador', value: 'identificador', sortable: false },
		{ text: 'Loja', value: 'loja', sortable: false },
		{ text: 'Cliente', value: 'displayCliente', sortable: false },
		{ text: 'Data', value: 'diplayDataHora', sortable: false },
		{ text: 'Ações', value: 'actions', align: 'center' },
	]

	get isClienteInformado() {
		return this.parametros.cliente && typeof this.parametros.cliente === 'object'
	}

	get isProdutoInformado() {
		return this.parametros.produto && typeof this.parametros.produto === 'object'
	}

	get tiposDeProdutos() {
		return { tipos: ['Padrão', 'Variante'] as TipoDeProduto [] }
	}

	get lojaDaNota() {
		return VendaModule.lojaDaEntrada
	}

	trocarSelecionarTodos() {
		this.selecionados = this.selecionados.length
			? []
			: (this.selecionados = [...this.itensFormatados])
	}

	voltar() {
		if (this.etapaDaDevolucao !== 'filtros') {
			this.etapaDaDevolucao = 'filtros'
			this.parametros.cliente = null
			this.focarNoPrimeiroCampo()
		} else {
			this.parametros.identificador = ''
			this.syncMostra = false
			this.$emit('novaNota')
		}
	}

	get showAlert() {
		return AlertModule.show
	}

	focarNoPrimeiroCampo() {
		setTimeout(() => {
			if (this.etapaDaDevolucao === 'filtros') {
				if (this.parametros.numeroDaNota || this.parametros.serieFiscal) {
					this.campoDeNumeroDaNota.focus()
				} else {
					this.campoDeNumeroDaVenda.focus()
				}
			} else {
				this.checkboxDeSelecionarTodos.focus()
			}
		})
	}

	async buscarVenda() {
		if ((this.parametros.cliente || this.parametros.produto) && this.parametros.identificador === '') {
			this.etapaDaDevolucao = 'pesquisas'
			this.buscarListagemDeVendasPorPagina()
		} else {
			try {
				this.buscando = true
				this.venda = null
				this.venda = await BuscarVendaParaDevolucaoUseCase({
					...this.parametros,
					idLoja: this.lojaDaNota?.id,
				})

				this.vendaOriginal = await this.findVendaUseCase.get(this.venda.id)
				this.selecionados = []
				this.etapaDaDevolucao ='escolhendo-itens'
				this.resetarParametros()
			} catch (error) {
				AlertModule.setError(error)
				this.etapaDaDevolucao ='pesquisas'
				this.parametros.identificador = ''
				return
			} finally {
				this.buscando = false
			}

		this.venda?.pontoDeVenda?.loja?.configuracaoDaLoja?.devolucaoParcial
			? (this.devolucaoParcial = true)
			: (this.devolucaoParcial = false)
		}
	}

	resetarParametros() {
		this.parametros.identificador = ''
		this.parametros.numeroDaNota = ''
		this.parametros.serieFiscal = ''
		this.parametros.cliente = null
	}

	async buscarListagemDeVendasPorPagina() {
		if (!VendaModule.lojaDaEntrada) return
		if (!this.parametros.cliente && !this.parametros.produto) return

		let vendasPaginadas

		try {
			this.buscandoVendas = true
			vendasPaginadas = await this.findVendaUseCase.list({
				lojaId: VendaModule.lojaDaEntrada.id || undefined,
				cliente: this.parametros.cliente ? this.parametros.cliente.cnpjOuCpf : undefined,
				identificadorProduto: this.parametros.produto ? this.parametros.produto.sku : undefined,
				isBuscaParaDevolucao: true,
				page: this.paginacao.page,
				size: this.paginacao.itemsPerPage,
				sort: 'dataHora,desc',
				tiposDeTransacao: VendaModule.emissaoEntradaAtual?.tipoDeTransacao === 'Conserto' ? VendaModule.emissaoEntradaAtual?.tipoDeTransacao : undefined,
				vendasSemTurno: true,
			})
		} catch(error) {
			AlertModule.setError(error)
			return
		} finally {
			this.buscandoVendas = false
		}

		this.vendasResumidas = vendasPaginadas.content
		this.paginacao.itemsLength = vendasPaginadas.totalElements
	}

	atualizarPagina(page) {
		this.paginacao.page += page
		this.buscarListagemDeVendasPorPagina()
	}

	get vendasFormatadas() {
		return this.vendasResumidas.map(venda => ({
			...venda,
			displayCliente: obterDisplayClienteDaVenda(venda),
			diplayDataHora: this.diplayDataHora(venda),
		}))
	}

	diplayDataHora(venda: ResumoDaVenda) {
		if (!venda.dataHora) return ''
		return dateTimeToPtBrFormat(venda.dataHora)
	}

	get itensFormatados() {
		return (
			this.venda?.itens.map(item => ({
				...item,
				displayEans: item.produto.eans.length
					? item.produto.eans.join(', ')
					: item.produto.sku,
			})) || []
		)
	}

	get totalDeItens() {
		if (this.devolucaoParcial) {
			const totalDeItensParaDevolver = this.selecionados.reduce(
				(total, item) => total + item.quantidade,
				0,
			)
			return !Number.isInteger(totalDeItensParaDevolver)
				? totalDeItensParaDevolver.toFixed(3)
				: totalDeItensParaDevolver
		} else {
			return this.venda?.itens.length
		}
	}

	async devolver() {
		if (!this.venda) return
		if (!this.validarTodasAsPaginas()) return
		if (!this.form.validate()) return
		if (!this.vendaOriginal) return
		try {
			this.iniciandoDevolucao = true
			if (this.devolucaoParcial) {
				await this.iniciarDevolucao.devolverVendaSemTurno({
					itensADevolver: this.selecionados,
					vendaOrigem: this.venda,
					vendaOriginal: this.vendaOriginal,
					tipoDeTransacao: VendaModule.emissaoEntradaAtual?.tipoDeTransacao || this.venda.tipoDeTransacao,
				})
			} else {
				await this.iniciarDevolucao.devolverVendaSemTurno({
					itensADevolver: this.venda?.itens,
					vendaOrigem: this.venda,
					vendaOriginal: this.vendaOriginal,
					tipoDeTransacao: VendaModule.emissaoEntradaAtual?.tipoDeTransacao || this.venda.tipoDeTransacao,
				})
			}
			this.$router
				.push({ name: this.$route.name || '' })
				.catch()

			this.$router
				.push({
					name: this.$route.name as string,
					query: { devolucaoBuscada: 'true' },
				})
				.catch()

			this.parametros.identificador = ''
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.iniciandoDevolucao = false
		}
		this.etapaDaDevolucao = 'filtros'
		this.resetarParametros()
	}

	validarTodasAsPaginas() {
		const itemInvalido = this.selecionados.find(
			item => typeof this.validarItemDeDevolucao(item) === 'string',
		)
		if (!itemInvalido) return true

		const index = this.itensFormatados.findIndex(
			({ id }) => id === itemInvalido.id,
		)
		if (index === -1) return false

		this.pagina = Math.ceil(index / 6)
		setTimeout(() => {
			this.form.validate()
		})

		return false
	}

	validarItemDeDevolucao(item: ItemDaVenda) {
		const itemOriginal = this.venda?.itens.find(({ id }) => id === item.id)
		if (!itemOriginal) return 'Item selecionado não encontrado'
		const regras = [
			maiorQueZero,
			quantidade => this.validarQuantidade(quantidade, itemOriginal.id),
		]
		for (const regra of regras) {
			const validacao = regra(item.quantidade)
			if (validacao !== true) return validacao
		}
		return true
	}

	validarQuantidade(quantidade: number, id: string) {
		const quantideOriginal = this.obterQuantidadeOriginalDoItem(id)
		if (quantidade > quantideOriginal) return 'Quantidade excedida'
		return true
	}

	obterQuantidadeOriginalDoItem(id: string) {
		const item = this.venda?.itens.find(item => item.id === id)
		if (!item) return 0
		return item.quantidade
	}

	estaSelecionado(itemId: string) {
		const item = this.selecionados.find(item => item.id === itemId)
		if (!item) return true
		return false
	}

	@Watch('mostra', { immediate: true })
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this.parametros = criarParametrosDeBusca(VendaModule.lojaDaVenda?.id)
		setTimeout(() => {
			this.form.resetValidation()
			this.focarNoPrimeiroCampo()
		})

		if (this.parametroIdentificador !== null) {
			this.parametros.identificador = this.parametroIdentificador
		}
	}

	@Watch('showAlert')
	onChangeShowAlert(show: boolean) {
		if (!show && this.syncMostra) this.focarNoPrimeiroCampo()
	}

	@Watch('etapaDaDevolucao')
	onChangeEscolhendoItens(etapaDaDevolucao: string) {
		if (etapaDaDevolucao !== 'filtros') return
		this.focarNoPrimeiroCampo()
	}

	@Watch('parametros.produto')
	onChangeProduto(produtoAtual: Produto) {
		if (produtoAtual) {
			this.campoDeProduto.blur()
		}
	}
	
	selecionarVendaParaDevolucao(vendaSelecionada: ResumoDaVenda | Venda) {
		if (!vendaSelecionada.identificador) return

		this.parametros.identificador = vendaSelecionada.identificador
		this.buscarVenda()
	}
}

function criarParametrosDeBusca(idLoja: string | undefined) {
	return {
		identificador: '',
		idLoja: idLoja || '',
		numeroDaNota: '',
		serieFiscal: '',
		cliente: null as Cliente | null,
		produto: null as Produto | null,
	}
}
