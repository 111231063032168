





















































































import { Vue, Component, PropSync, Ref, Prop, Watch } from 'vue-property-decorator'
import { ItemDaVenda, Venda } from '@/models'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { menorQueZero, maiorQueZero, obrigatorio } from '@/shareds/regras-de-form'
import { FindPerfilUseCase, FindUsuarioUseCase } from '@/usecases'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { v4 as uuidv4 } from 'uuid'
import AlterarQuantidadeLimiteDoProduto from '@/components/ui/AlterarQuantidadeLimiteDoProduto.vue'
import AplicarTabelaDePrecoUseCase from '@/usecases/tabela-de-precos/AplicarTabelaDePrecoUseCase'

@Component({
	components: {
		CampoDecimal,
		AlterarQuantidadeLimiteDoProduto,
	},
})
export default class CamposDeItemDaNota extends Vue  {
	@Prop({type: Boolean}) podeSerPositivo!: boolean
	@Prop({type: Boolean}) podeSerNegativo!: boolean
	@Prop({type: Boolean}) permissaoConcedida!: boolean
	@PropSync('value') item!: ItemDaVenda
	@Ref() campoDeQuantidade!: HTMLInputElement
	@Ref() alterarQuantidadeLimiteDoProduto!: AlterarQuantidadeLimiteDoProduto

	findUsuarioUseCase = new FindUsuarioUseCase()
	findPerfilUseCase = new FindPerfilUseCase()

	menorQueZero = menorQueZero
	maiorQueZero = maiorQueZero 
	obrigatorio = obrigatorio

	podeAlterarQuantidade = false
	mostrarDialog = false
	quantidade = 0
	pagina = 1
	listaDeControle = [{ 
		indice: 0,
		quantidade: 0,
	}]
	headers = [
		{
			text: 'Produto',
			value: 'produto.nome',
			class: 'text-no-wrap',
			width: '100%',
		},
		{
			text: 'Código',
			value: 'displayEans',
			class: 'text-no-wrap',
			align: 'right',
		},
		{ text: 'Quantidade', value: 'quantidade', class: 'text-no-wrap' },
	]


	@Watch("mostrarDialog")
	changeItemVenda() {
		if(this.mostrarDialog)
			this.quantidade = this.item.quantidade
	}

	public focus() {
		this.campoDeQuantidade.focus()
	}

	get podeAlterarQuantidadeProduto() {
		return UserLoginStore.permiteRegraDeNegocio('informar-quantidade-de-produtos') && (
			(this.venda.tipoDeTransacao === 'Devolução' && this.venda.tipoNota === 'Saída') || (this.venda.tipoDeTransacao !== 'Devolução')
		)
	}

	get podeAlterarQuantidadeSemAutenticacao() {
		return UserLoginStore.permiteRegraDeNegocio('pode-alterar-quantidade-de-produto')
	}

	get loja() {
		return VendaModule.lojaDaEntrada
	}

	get venda() {
		return VendaModule.emissaoEntradaAtual as Venda
	}

	async concluir() {
		if(!this.loja) return

		const tipoDeCliente =
			this.venda && this.venda.tipoDeCliente ? this.venda.tipoDeCliente : null

		const itens = this.normalizaCasoSejaComposto(this.item).map(itemMap => ({
			...itemMap,
			desconto: itemMap.desconto,
		}))
		

		try {
			await AplicarTabelaDePrecoUseCase({
				loja: this.loja,
				itens,
				tipoDeCliente,
				validarLimiteDeProduto: true,
				vendaAtual: this.venda,
			})

			this.mostrarDialog = false
			this.item.quantidade = this.quantidade
			
		} catch(error) {
			AlertModule.setError(error)
			if (AlertModule.getErrorMsg === 'Cliente já esgotou limite de compras desse produto no dia atual'){
				this.mostrarDialog = false
				if (!(await VendaModule.autenticarAcaoTelaDeCaixa({ regra:'pode-alterar-quantidade-de-produto' })) || !this.venda) return 
				this.item.quantidade = this.quantidade
			}
		}

	}

	normalizaCasoSejaComposto(item: ItemDaVenda) {
		let itens: ItemDaVenda[] = []
		
		if (item.produto.tipo === 'Composto') {
			const idConjunto = uuidv4()

			itens = item.produto.itens.map(itemDoProduto => ({
				...item,
				produto: itemDoProduto.produto,
				quantidade: item.quantidade * itemDoProduto.quantidade,
				idConjunto: idConjunto,
				desconto: itemDoProduto.desconto,
			}))
		} else {
			itens = [item]
		}

		return itens
	}
}

