







































































































































































































































import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'
import { formatarCnpjOuCpf, formatarMoeda } from '@/shareds/formatadores'
import CamposDeItemDaNota from './CamposDeItemDaNota.vue'
import { Venda, ItemDaVenda, Loja } from '@/models'
import {
	obterTotalDaVenda,
	obterTotalDoItem,
	contarItensDaVenda,
	obterSubTotalDoItem,
	obterTotalDosItensDaVenda,
	unificarItensDaVendaPorProduto,
} from '@/shareds/venda-shareds'
import UserLoginStore from '../../../store/vuex/authentication/UserLoginStore'
import BotaoDeDesconto from '@/views/application/venda/BotaoDeDesconto.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import {
	displayNomeCompletoDoProduto,
	displayVariantes,
} from '@/shareds/produto-shareds'
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils'
import { FindEstoqueUseCase } from '@/usecases'
import CampoDeClienteNaEmissaoDeNota from './CampoDeClienteNaEmissaoDeNota.vue'
import CampoDeFornecedorNaEmissaoDeNota from './CampoDeFornecedorNaEmissaoDeNota.vue'
import { nextTick } from '@/shareds/utils'
import { obrigatorio } from '@/shareds/regras-de-form'
import AplicarTabelaDePrecoUseCase from '@/usecases/tabela-de-precos/AplicarTabelaDePrecoUseCase'
import AplicarPrecoDeCustoUseCase from '@/usecases/tabela-de-precos/AplicarPrecoDeCustoUseCase'

@Component({
	components: {
		CamposDeItemDaNota,
		BotaoDeDesconto,
		Confirmacao,
		CampoDeClienteNaEmissaoDeNota,
		CampoDeFornecedorNaEmissaoDeNota,
	},
})
export default class CardDeEntradas extends Vue {
	@Prop({ type: Boolean }) pedindoCliente!: boolean
	@Prop({ type: Boolean }) loadingPagar!: boolean
	@Prop({ type: Boolean }) disabledPagar!: boolean
	@Prop({ type: Boolean }) permissaoConcedida!: boolean
	@Prop({ type: Boolean }) vendaComErro!: boolean
	@Ref() formVenda!: HTMLFormElement
	@Ref() panelHeaders!: { $el: HTMLElement }[]
	@Ref() camposDeVenda!: CamposDeItemDaNota[]
	@Ref() botaoDePagamento!: { $el: HTMLButtonElement }
	@Ref() botaoDeFechamentoDeCaixa!: { $el: HTMLButtonElement }
	@Ref() botaoDeCancelamentoDeVenda!: { $el: HTMLButtonElement }
	@Ref() campoDeCliente!: CampoDeClienteNaEmissaoDeNota
	@Ref() campoDeFornecedor!: CampoDeFornecedorNaEmissaoDeNota
	@Ref() formsDeCamposDaVenda!: HTMLFormElement[]
	@Ref() painelExpansao!: HTMLDivElement
	@Ref() formDoDialogoDeCliente!: HTMLFormElement
	@Ref() campoDeClienteDoDialogo!: CampoDeClienteNaEmissaoDeNota

	displayVariantes = displayVariantes
	formatarMoeda = formatarMoeda
	displayNomeCompletoDoProduto = displayNomeCompletoDoProduto
	obterSubTotalDoItem = obterSubTotalDoItem
	obterTotalDoItem = obterTotalDoItem
	contarItensDaVenda = contarItensDaVenda
	painel: number | null = null
	zIndexPagamentoEmAndamento = 13 // para o botão ficar em cima do overlay quando o pagamento ja foi inicializado
	menuAtivo: number[] = []
	buscandoCliente = false
	obrigatorio = obrigatorio
	item!: ItemDaVenda

	findEstoqueUseCase = new FindEstoqueUseCase()

	cpfOuCnpjDoCliente: null | string =
		VendaModule.emissaoEntradaAtual && VendaModule.emissaoEntradaAtual.cpfDoCliente
			? formatarCnpjOuCpf(VendaModule.emissaoEntradaAtual.cpfDoCliente)
			: null

	get lojaAtual() {
		return VendaModule.lojaDaEntrada
	}

	get isDevolucaoDeSaida() {
		return this.venda.tipoDeTransacao === 'Devolução' && this.venda.tipoNota === 'Saída'
	}

	get consultarPrecoDeCusto() {
		if (!this.venda) return false

		return this.venda.isBrinde ||
			this.venda.tipoDeTransacao === 'Transferência' ||
			(this.venda.tipoDeTransacao === 'Devolução' && this.venda.tipoNota === 'Saída') ||
			this.venda.tipoDeTransacao === 'Outros'
	}

	async validarSeVendaExigeCpf() {
		if(!this.loja) return 
		if(!this.venda) return
		if(this.venda.isConsignado && !this.venda.cliente) return AlertModule.setError('Venda consignada exige cpf/cnpj do destinatário')
		if(this.venda.isDemonstracao && !this.venda.cliente) return AlertModule.setError('Venda de demonstração exige cpf/cnpj do destinatário')
		if(!this.venda.cliente && !this.venda.cpfDoCliente) return AlertModule.setError('Toda emissão de DANFE exige cpf/cnpj do destinatário')
		if(this.venda.tipoNota === 'Saída') {
			if (!this.venda.cpfDoCliente) return AlertModule.setError('Toda emissão de DANFE exige cpf/cnpj do destinatário')

			if (this.venda.tipoDeTransacao === 'Transferência' 
					&& this.venda.cpfDoCliente.substring(0, 8) !== this.loja.cnpj.substring(0, 8)) {
				return AlertModule.setError('Não é possível emitir uma transferência para outro CNPJ raiz')
			}
		}

		const tipoDeCliente = this.venda && this.venda.tipoDeCliente
			? this.venda.tipoDeCliente
			: null

		const itens = this.venda.itens
		try {

			if (this.consultarPrecoDeCusto) {
				await AplicarPrecoDeCustoUseCase({
					loja: this.loja,
					itens,
				})
			} else {
				await AplicarTabelaDePrecoUseCase({
					loja: this.loja,
					itens,
					tipoDeCliente,
					validarLimiteDeProduto: true,
					vendaAtual: this.venda,
				})
			}
			
			this.$emit('pagar', this.venda)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	get cadastroDoClienteIncompleto() {
		return () =>
			!this.venda ||
			!!this.venda.cliente
	}

	get venda() {
		return VendaModule.emissaoEntradaAtual as Venda
	}

	set venda(venda: Venda) {
		VendaModule.setEmissaoEntradaAtual(venda)
	}

	get total() {
		return obterTotalDaVenda(this.venda)
	}

	get subtotal() {
		return obterTotalDosItensDaVenda(this.venda)
	}

	get loja() {
		return VendaModule.lojaDaEntrada
	}

	set loja(loja: Loja | null) {
		VendaModule.setLojaDaEntrada(loja)
	}

	get recarregaVenda() {
		return VendaModule.recarregaNota
	}

	set recarregaVenda(recarregaVenda: boolean) {
		VendaModule.setRecarregaNota(recarregaVenda)
	}

	get totalDaVenda() {
		return this.validarTotalDaVenda(this.venda)
	}

	get devolucaoParcial() {
		return !this.loja?.configuracaoDaLoja.devolucaoParcial
	}

	created() {
		document.addEventListener('keydown', this.atalhoDeUltimaVenda)
		document.addEventListener('keydown', this.atalhoDePagar)
		document.addEventListener('keydown', this.atalhoDeNovaVenda)
		document.addEventListener('keydown', this.atalhoDeClienteOuCpf)
		this.$emit('update:pedindoCliente', VendaModule.lembraDePedirOCliente)
	}

	mounted() {
		this.focarNaTela()
	}

	async focarNaTela() {
		await nextTick()
		if (!this.pedindoCliente) {
			this.focarCampoDaBuscaDeProduto()
		} else {
			this.campoDeClienteDoDialogo.focus()
		}
	}

	pedirCliente() {
		this.$emit('update:pedindoCliente', true)
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeUltimaVenda)
		document.removeEventListener('keydown', this.atalhoDePagar)
		document.removeEventListener('keydown', this.atalhoDeNovaVenda)
		document.removeEventListener('keydown', this.atalhoDeClienteOuCpf)
	}

	atalhoDeClienteOuCpf(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 'c') {
			event.preventDefault()
			this.campoDeCliente.focus()
		}
	}

	validarTotalDoItem(item: ItemDaVenda) {
		let totalDoItem = obterTotalDoItem(item)
		if (this.venda.tipoDeTransacao === 'Devolução') {
			return totalDoItem
		} else if (totalDoItem < 0) {
			return (totalDoItem = 0)
		}
		return totalDoItem
	}

	validarTotalDaVenda(venda: Venda) {
		let totalDaVenda = obterTotalDaVenda(venda)
		if (this.venda.tipoDeTransacao === 'Devolução') {
			return totalDaVenda
		} else if (totalDaVenda < 0) {
			return (totalDaVenda = 0)
		}
		return totalDaVenda
	}

	validarSubTotalDaVenda(venda: Venda) {
		let subTotalDaVenda = obterTotalDosItensDaVenda(venda)
		if (this.venda.tipoDeTransacao === 'Devolução') {
			return subTotalDaVenda
		} else if (subTotalDaVenda < 0) {
			return (subTotalDaVenda = 0)
		}
		return subTotalDaVenda
	}

	atalhoDePagar(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 'r') {
			event.preventDefault()
			this.botaoDePagamento.$el.click()
		}
	}

	colorItem(item: any) {
		if(this.venda.isConsignado) return ['yellow lighten-4']
		if(item.isBrinde) return ['pink lighten-4']			
		if(item.idConjunto !== null && item.idConjunto !== '') return ['primary lighten-4']
		if(this.venda.isDemonstracao) return ['grey']
		return ['white']	
	}

	atalhoDeUltimaVenda(event: KeyboardEvent) {
		if (this.panelHeaders && !this.panelHeaders.length) return
		if (event.altKey && event.key.toLowerCase() === 'u') {
			event.preventDefault()
			this.panelHeaders[this.panelHeaders.length - 1].$el.click()
			setTimeout(() => {
				this.camposDeVenda[this.camposDeVenda.length - 1].focus()
			})
		}
	}

	atalhoDeNovaVenda(event: KeyboardEvent) {
		if (!this.podeCancelarVenda) return
		if (event.altKey && event.key.toLowerCase() === 'n') {
			event.preventDefault()
			this.botaoDeCancelamentoDeVenda.$el.click()
		}
	}

	async pagarVenda() {
		const indice = this.formsDeCamposDaVenda.findIndex(form => !form.validate())
		if (indice !== -1) {
			this.painel = indice
			setTimeout(() => {
				this.panelHeaders[indice].$el.scrollIntoView()
			}, 300)
		}

		if (
			this.venda.cpfDoCliente &&
			!isValidCPF(this.venda.cpfDoCliente) &&
			!isValidCNPJ(this.venda.cpfDoCliente)
		) {
			AlertModule.setError('Informe um CPF / CNPJ válido')
			return
		}

		if (this.venda.tipoDeTransacao === 'Venda' && this.totalDaVenda <= 0) {
			AlertModule.setError(
				'Valor do desconto não pode ser maior que o valor do item',
			)
			return
		}

		if (
			(this.venda.tipoDeTransacao === 'Devolução' || this.venda.pedido) &&
			!this.venda.cpfDoCliente?.length
		) {
			AlertModule.setError('Informe um CPF ou CNPJ')
			return
		}
		if (
			(this.venda.tipoDeTransacao === 'Devolução' || this.venda.pedido) &&
			!this.venda.cliente?.id
		) {
			AlertModule.setError('Cliente não cadastrado no sistema')
			return
		}

		if (this.venda.pedido && !this.venda.cliente?.telefones.length) {
			AlertModule.setError('Cadastre um telefone para o cliente informado')
			return
		}

		if (!this.formVenda.validate()) {
			AlertModule.setError('Verique os dados cadastrados')
			return
		}

		try {
			const itensUnificados = unificarItensDaVendaPorProduto(this.venda.itens)
			await Promise.all(itensUnificados)
			this.$emit('pagar', this.venda)
		} catch (error) {
			AlertModule.setError(error)
			return
		}
	}

	validarPagamentos() {
		try {
			const pagamentosPorTef = VendaModule.emissaoEntradaAtual?.pagamentos.filter(
				pagamento =>
					pagamento.tipoDePagamento.formaDePagamento === 'TEF Crédito' ||
					pagamento.tipoDePagamento.formaDePagamento === 'TEF Débito',
			)

			if (pagamentosPorTef && pagamentosPorTef?.length > 0)
				throw new Error(
					'Existe pagamento(s) por tef autorizado(s) na venda atual, não é possível iniciar uma nova venda.',
				)

			this.iniciarNovaVenda()
		} catch (error) {
			AlertModule.setError(error)
			return
		}
	}

	async iniciarNovaVenda() {
		this.loja = null
		await VendaModule.iniciarNovaNota({ identificador: '', lojaId: '' })

		this.$router
			.push({
				name: this.$route.name as string,
				query: {},
			})

		this.$emit('cancelar')
		this.cpfOuCnpjDoCliente = null
		this.vendaComErro = false
		this.focarNaTela()
	}

	focarCampoDaBuscaDeProduto() {
		if (
			this.venda.cpfDoCliente &&
			(isValidCPF(this.venda.cpfDoCliente) ||
				isValidCNPJ(this.venda.cpfDoCliente))
		) {
			this.$emit('focarCampoDeBusca')
		}
	}

	get podeExcluirItemDeVenda() {
		return UserLoginStore.permiteRegraDeNegocio('pode-excluir-item-de-venda')
	}

	get podeCancelarVenda() {
		return UserLoginStore.permiteRegraDeNegocio('pode-cancelar-venda')
	}

	get podeConcederDesconto() {
		return UserLoginStore.permiteRegraDeNegocio('pode-conceder-desconto')
	}

	removerItemDaVenda(indice: number) {
		if (indice <= -1) return
		if (this.venda.itens[indice].idConjunto && this.venda.itens[indice].idConjunto !== null && this.venda.itens[indice].idConjunto !== "") {
			const itemDeletado = this.venda.itens[indice]

			while (
				this.venda.itens.some(
					item => item.idConjunto === itemDeletado.idConjunto,
				)
			) {
				const itemIndice = this.venda.itens.findIndex(
					produto => produto.idConjunto === itemDeletado.idConjunto,
				)
				this.venda.itens.splice(itemIndice, 1)
			}
		} else {
			this.venda.itens.splice(indice, 1)
		}

		VendaModule.setEmissaoEntradaAtual(this.venda)

		if (
			this.venda.itens.length === 0 &&
			this.venda.tipoDeTransacao == 'Devolução'
		) {
			this.iniciarNovaVenda()
		}
	}

	async projetaEstoqueDosItensDaVenda(itens: ItemDaVenda[]): Promise<boolean> {
		const projecoesPorItemDaVenda = await Promise.all(
			itens.map(async itemDaVenda => {
				let podePagarOsItens = true
				await this.findEstoqueUseCase
					.getEstoqueDoItemDaVendaNaLoja(
						itemDaVenda.produto.id,
						itemDaVenda.quantidade.toString(),
						this.loja?.id || '',
					)
					.catch(error => {
						podePagarOsItens = false
						AlertModule.setError(error.response.data[0])
					})
				return podePagarOsItens
			}),
		)
		return projecoesPorItemDaVenda.some(
			estoqueNegativo => estoqueNegativo === false,
		)
	}

	@Watch('venda', { immediate: true, deep: true })
	onChangeVenda(vendaAtual: Venda, vendaAnterior: Venda | null) {
		this.cpfOuCnpjDoCliente = vendaAtual.cpfDoCliente

		if (
			!vendaAnterior ||
			vendaAtual.itens.length > vendaAnterior.itens.length
		) {
			setTimeout(() => {
				this.panelHeaders &&
					this.panelHeaders[this.panelHeaders.length - 1].$el.scrollIntoView()
			})
		}
	}

	confirmarCliente() {
		if (!this.formDoDialogoDeCliente.validate()) return
		this.$emit('update:pedindoCliente', false)
		this.focarNaTela()
	}

	async confirmarSemCliente() {
		this.$emit('update:pedindoCliente', false)
		this.venda.cpfDoCliente = null
		this.venda.cliente = null
		this.cpfOuCnpjDoCliente = null
		this.focarNaTela()
	}

	@Watch('recarregaVenda')
	onRecarregarVenda(recarregarVenda: boolean) {
		if (!this.venda) return
		if (!recarregarVenda) return
		this.cpfOuCnpjDoCliente = formatarCnpjOuCpf(
			this.venda.cliente?.cnpjOuCpf || this.venda.cpfDoCliente || '',
		)
		this.recarregaVenda = false
	}

	@Watch('buscandoCliente', { immediate: true })
	onChangeBuscandoCliente(valor: boolean) {
		this.$emit('buscandoCliente', valor)
	}
}
