





































































































import { Vue, Component, PropSync } from 'vue-property-decorator'
import { Venda } from '@/models'
import { formatarMoeda } from '@/shareds/formatadores'
import {
	obterTotalDoItem,
	obterTotalDosItensDaVenda,
	contarItensDaVenda,
	obterTotalDaVenda,
	obterTrocoDaVenda,
	obterDescontoDaVenda,
	obterSubTotalDoItem,
	obterValorAPagar,
	obterItensComTaxaAplicada,
	obterTotalDoItemComTaxas,
	obterTotalDeTaxas,
	obterValorDeFrete,
} from '@/shareds/venda-shareds'

@Component
export default class ResumoDaNota extends Vue  {
	@PropSync('value') venda!: Venda

	formatarMoeda = formatarMoeda
	obterTotalDoItem = obterTotalDoItem
	obterTotalDosItensDaVenda = obterTotalDosItensDaVenda
	obterTotalDaVenda = obterTotalDaVenda
	contarItensDaVenda = contarItensDaVenda
	obterDescontoDaVenda = obterDescontoDaVenda
	obterSubTotalDoItem = obterSubTotalDoItem
	obterItensComTaxaAplicada = obterItensComTaxaAplicada
	obterTotalDoItemComTaxas = obterTotalDoItemComTaxas
	obterTotalDeTaxas = obterTotalDeTaxas
	obterValorDeFrete = obterValorDeFrete
	get restante() {
		return this.venda.tipoDeTransacao === 'Venda' ? obterValorAPagar(this.venda) : 0
	}

	get troco() {
		return obterTrocoDaVenda(this.venda)
	}

	get tipoDaTransacao() {
		return this.venda.tipoDeTransacao.toUpperCase()
	}
}

